import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import Image from "@components/common/CloudinaryImage";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs Online en directe Drets Humans i Deontologia Professional (Diumenge 17 març
                    16:00 h - 20:00 h) [37a Promoció]
                </Title>
                <Text>
                    Et presentem el millor curs de reforç per preparar l'examen del 20 de març de
                    Drets Humans.
                    <br />
                    <br />
                    Un any més torna una nova edició i ja s'ha convertit en una formació molt
                    necessària any rere any
                    <br />
                    <br />
                    Un curs de 4 hores en directe a través de ZOOM amb una de les millors docents.
                    <br />
                    <br />
                    Durant el curs ens centrarem en els conceptes clau i susceptibles de sortir en
                    examen.
                    <br />
                    <br />
                    El curs està basat en el temari oficial de la 37a promoció.
                    <br />
                    <br />
                    Diumenge 17 de març
                    <br />
                    <br />
                    De 16.00 20.00h
                    <br />
                    <br />
                    El repàs final per treure bona nota el dia de l'examen oficial
                    <br />
                    <br />
                    El curs és en directe i quedarà gravat fins al dia de l'examen
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
